import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import { Text } from 'stile-shared/src/components/2020/common/Primitives';
import { StileXSampleButton } from 'stile-shared/src/components/2020/common/StileXSampleButton';
import { Column } from 'stile-shared/src/components/2020/layout/Column';
import { theme } from 'stile-shared/src/styles/2020/theme';

import { StileXSampleFormUs } from './StileXSampleFormUs';

type StileXSampleButtonUsProps = Omit<
  React.ComponentProps<typeof StileXSampleButton>,
  'form' | 'content'
>;

export function StileXSampleButtonUs(props: StileXSampleButtonUsProps) {
  return (
    <StileXSampleButton
      {...props}
      gtagEvent="stile_x_sample_button_us"
      form={<StileXSampleFormUs />}
      content={
        <Column space={theme.space.mToL}>
          <Column>
            <Text as="h3" size="l" semiBold>
              Stile X in your District
            </Text>
            <Text>
              Stile X is designed to help your students be the best learners they can be. With Stile
              X, your students will revise key concepts from class while practicing those
              all-important study and note-taking skills.
            </Text>
            <Text>
              Stile X is a core component of Stile’s phenomena-based curriculum. If you’ve
              customized Stile for your district, you can pick and choose the Stile X booklets that
              match your scope and sequence.
            </Text>
            <Text>We look forward to further supporting you and your students with Stile X!</Text>
          </Column>

          <StaticImage
            src="../../../../stile-shared/assets/images/stile-x/booklets-open.jpg"
            alt="Three colourful booklets spread out, with a fourth booklet open on top. The questions in the open book have been filled out"
          />
        </Column>
      }
    />
  );
}
