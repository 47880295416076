import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import iconDualCodingSvg from 'stile-shared/assets/icons/icon_dual_coding.svg';
import iconElaborationSvg from 'stile-shared/assets/icons/icon_elaboration.svg';
import iconMetacognitionSvg from 'stile-shared/assets/icons/icon_metacognition.svg';
import iconRepetitionSvg from 'stile-shared/assets/icons/icon_repetition.svg';
import iconRetrievalSvg from 'stile-shared/assets/icons/icon_retrieval.svg';
import iconSummarisationSvg from 'stile-shared/assets/icons/icon_summarisation.svg';
import stileXExplainerVideo from 'stile-shared/assets/videos/stile_x_explainers.mp4';

import { DeviceTeaserBlock } from 'stile-shared/src/components/2020/blocks/DeviceTeaserBlock';
import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { IconCardsBlock } from 'stile-shared/src/components/2020/blocks/IconCardsBlock';
import { TeaserBlock } from 'stile-shared/src/components/2020/blocks/TeaserBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { H2, P, Section, Ul } from 'stile-shared/src/components/2020/common/Primitives';
import { Quote } from 'stile-shared/src/components/2020/common/Quote';
import { Center } from 'stile-shared/src/components/2020/layout/Center';
import { Column } from 'stile-shared/src/components/2020/layout/Column';
import { Row } from 'stile-shared/src/components/2020/layout/Row';

import { getPageData } from 'utils/getPageData';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';
import { StileXSampleButtonUs } from 'components/common/StileXSampleButtonUs';

export { Head } from 'templates/2020/Head';

function StileXPage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.2}
            src="../../../../stile-shared/assets/images/stile-x/cover_stile_x.jpg"
            alt="Colorful Stile X notebooks fanned out on a desk to resemble a rainbow"
          />
        }
      />

      <TextBlock space={theme.space.l}>
        <P>
          Stile X is a collection of hard-copy student revision and note-taking booklets. They
          promote student agency and help students learn how to study, while they study.
        </P>

        <Quote
          text="Stile is great by itself, but it’s even better with Stile X."
          credit="Peter Eyre, Montmorency Secondary College"
        />

        <Row>
          <StileXSampleButtonUs />
        </Row>
      </TextBlock>

      <TextBlock>
        <H2>Stunningly beautiful booklets</H2>

        <Column>
          <P>
            We’ve crafted a unique Stile X booklet for every Stile Unit, collectively covering the
            NGSS for middle school. They focus on the consolidation and mastery of the most
            important concepts from each unit, so even if you’ve deeply customized Stile for your
            school, they remain an invaluable resource.
          </P>

          <P>Every booklet contains:</P>
          <Ul>
            <li>
              <strong>Scaffolded note-taking</strong> for the most important concepts in every unit
            </li>
            <li>
              <strong>A practice test</strong> aligned to the learning goals in Stile’s lessons,
              with fully-worked model answers.
            </li>
            <li>
              <strong>A glossary</strong> of all the topic’s key terminology
            </li>
            <li>
              <strong>Study and note-taking tips</strong> that are evidence based and age
              appropriate
            </li>
          </Ul>
        </Column>
      </TextBlock>

      <TeaserBlock
        contentSide="right"
        contentMax="560px"
        title="Structured note-taking"
        text="Rigorous scaffolding in Stile X supports students in taking handwritten notes to consolidate knowledge from class"
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/stile-x/stile_x_structured.jpg"
            alt="A close up of a Stile X worksheet, with a hand filling in one of the questions"
          />
        }
      />

      <DeviceTeaserBlock
        title="Video explainers"
        text="Over 200 60-second summary videos capture core curriculum content to help students revise (think TikTok meets Khan Academy!)"
        videoSrc={stileXExplainerVideo}
      />

      <DeviceTeaserBlock
        contentSide="right"
        title="Flashcards and glossary"
        text="Students can quickly look up definitions of terms used in class and master them with personalized flashcard games"
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/stile-x/stile_x_flashcards.png"
            alt="A screenshot of the Stile X app, featuring a flashcard on the definition of gravity"
          />
        }
      />

      <TeaserBlock
        contentMax="560px"
        title="Practice tests"
        text="New revision questions and fully-worked model answers help students study for tests and check their understanding against learning goals"
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/stile-x/stile_x_practice.jpg"
            alt="A closeup of a Stile X quiz, with a hand filling it out"
          />
        }
      />

      <TeaserBlock
        variant="dark"
        contentSide="right"
        title="Some things are better on paper"
        asideWidth="800px"
        image={
          <StaticImage
            aspectRatio={1.8}
            src="../../../../stile-shared/assets/images/stile-x/stile_x_paper.jpg"
            alt="A closeup of handwritten notes in Stile X"
          />
        }
      >
        <P>
          When taking notes, pen and paper are hard to beat. Stile X reimagines how we use ink
          alongside modern, digital tools to meaningfully improve student outcomes. With Stile X,
          students create summary notes, practice their handwriting, and unwind with mindful
          colouring.{' '}
          <Link
            variant="inherit"
            url="https://journals.sagepub.com/doi/abs/10.1177/0956797614524581"
          >
            Research suggests that physical note-taking improves cognitive understanding, recall and
            grades.
          </Link>
        </P>
      </TeaserBlock>

      <TeaserBlock
        style={{ paddingTop: 0 }}
        variant="dark"
        title="Empower your students to take control of their learning"
        asideWidth="800px"
        image={
          <StaticImage
            aspectRatio={1.8}
            src="../../../../stile-shared/assets/images/stile-x/stile_x_empower.jpg"
            alt="A closeup showing someone filling out a rating of their understanding in Stile X"
          />
        }
      >
        <P>
          Stile X is an entirely student-directed resource with opportunities for students to
          develop intrinsic motivation, connect their learning to their lives and track their
          progress. As students progress from Grade 6 to Grade 8, the study and note-taking skills
          become progressively more complex, giving your students the tools they need to succeed.{' '}
          <Link
            variant="inherit"
            url="https://blog.stileeducation.com/stile-x-study-skills/"
            openInNewTab
          >
            Read more about the progression of skills in Stile X
          </Link>
          .
        </P>
      </TeaserBlock>

      <TextBlock title="Stile X perfectly complements Stile">
        <P>
          After completing an engaging in-class lesson, students can revise and master the key
          concepts in Stile X. This leaves more class time to tackle real-world problems,
          higher-order concepts and hands-on investigations.
        </P>

        <P>
          Plus, parents can more easily get involved with student learning at home by simply leaning
          over the beautifully bound book!
        </P>
      </TextBlock>

      <Section>
        <Center>
          <StaticImage
            src="../../../../stile-shared/assets/images/stile-x/stile_x_class_home.png"
            alt="An illustration showing a student working in two locations, one at school with a teacher helping and one at home with her father helping"
          />
        </Center>
      </Section>

      <TextBlock textAlign="center" title="Backed by rigorous cognitive research">
        <P>
          <Link url="https://blog.stileeducation.com/the-science-of-stile-x/">
            Read all about the science of Stile X
          </Link>
          , the evidence-based learning strategies embedded into the design and contents of Stile X.
        </P>
      </TextBlock>

      <IconCardsBlock
        max="1000px"
        cards={[
          {
            icon: { src: iconRetrievalSvg },
            title: 'Retrieval practice',
            text: 'Retrieval practice asks students to actively recall information frequently and in different forms to boost long-term retention and transfer of knowledge to new contexts.',
          },
          {
            icon: { src: iconMetacognitionSvg },
            title: 'Metacognition',
            text: 'Metacognition supports students in monitoring their own learning by encouraging them to evaluate their level of comprehension, task performance, and the efficacy of their study strategies.',
          },
          {
            icon: { src: iconRepetitionSvg },
            title: 'Spaced repetition',
            text: 'Spaced repetition involves revisiting a concept multiple times over a time period to facilitate better understanding and recall.',
          },
          {
            icon: { src: iconSummarisationSvg },
            title: 'Summarisation',
            text: 'Summarisation helps students identify key ideas and relevant details to improve memory and understanding of the concept.',
          },
          {
            icon: { src: iconElaborationSvg },
            title: 'Elaboration',
            text: 'Elaboration is all about adding details to knowledge, for instance connecting to prior knowledge or something personal, to help students deeply understand the concept and more easily remember it.',
          },
          {
            icon: { src: iconDualCodingSvg },
            title: 'Dual coding',
            text: 'Dual coding involves presenting concepts in multiple ways e.g. verbal, visual and textual, to give students multiple pathways to remember, understand and access that knowledge.',
          },
        ]}
      />

      <TeaserBlock
        contentSide="right"
        contentMax="430px"
        title="Request a sample"
        text="Stile X is the ultimate student companion to your Stile-ish classroom. Request a sample to get a firsthand look at how Stile X will help your students succeed in science and beyond!"
        button={<StileXSampleButtonUs />}
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/stile-x/stile_x_sign_up.jpg"
            alt="Colorful Stile X booklets fanning out in a rainbow pattern"
          />
        }
      />

      <NextPageBlock path="/what-is-stile/lab-kits/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default StileXPage;
