import * as React from 'react';

import FilePlayer from 'react-player/file';
import styled, { css } from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

import { H2, Section, Text } from '../../common/Primitives';
import { Column } from '../../layout/Column';

type DeviceTeaserBlockProps = React.ComponentProps<typeof Section> & {
  title: string;
  text: string;
  image?: React.ReactNode;
  videoSrc?: string;
  contentSide?: 'left' | 'right';
};

export function DeviceTeaserBlock({
  title,
  text,
  image,
  videoSrc,
  contentSide = 'left',
  children,
  ...props
}: React.PropsWithChildren<DeviceTeaserBlockProps>) {
  return (
    <Container {...props} contentSide={contentSide}>
      <Content>
        <H2>{title}</H2>
        {text ? <Text>{text}</Text> : children}
      </Content>

      <Device>
        <DeviceInner>
          {image}

          {videoSrc && (
            <FilePlayer url={videoSrc} width="100%" height="100%" playing playsinline loop muted />
          )}
        </DeviceInner>
      </Device>
    </Container>
  );
}

const Device = styled.div<Pick<DeviceTeaserBlockProps, 'contentSide'>>`
  display: flex;
  align-items: center;
  justify-content: center;

  img,
  video {
    border-radius: 40px;
    border: 6px solid white;
  }
`;

const Content = styled(Column)``;

const DeviceInner = styled.div`
  max-width: 400px;
`;

const Container = styled(Section)<Pick<DeviceTeaserBlockProps, 'contentSide'>>`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: flex-start;
  gap: ${theme.space.l};

  & ${Content} {
    flex: 1 1 50%;
    max-width: ${theme.space.measure};
  }

  & ${Device} {
    flex: 1 1 50%;
  }

  @media ${theme.media.min.m} {
    & ${Content} {
      margin-top: calc(${theme.space.xxxl} * -1);
      order: ${({ contentSide }) => (contentSide != 'left' ? 1 : -1)};
    }

    ${Device} {
      position: relative;
      top: ${theme.space.l};
      margin-bottom: ${theme.space.s};
      filter: drop-shadow(${theme.shadows.drop});

      ${({ contentSide }) => css`
        /* Negative margin to prevent rotated image/video moving outside viewport */
        ${contentSide}: calc(${theme.space.l} * -1);

        ${DeviceInner} {
          transform: rotate(${contentSide === 'left' ? 15 : -15}deg);
        }
      `}
    }
  }

  @media ${theme.media.min.m} {
    flex-direction: row;

    & + & ${Device} {
      top: 0;
    }
  }
`;
