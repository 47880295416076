import * as React from 'react';

import { Button } from '../Button';
import { useModal } from '../Modal';
import { StileXSampleModal } from '../StileXSampleModal';

type StileXSampleButtonProps = React.ComponentProps<typeof Button> &
  Pick<React.ComponentProps<typeof StileXSampleModal>, 'form' | 'content'>;

export function StileXSampleButton({ form, content, ...props }: StileXSampleButtonProps) {
  const { openModal, closeModal, isModalOpen } = useModal();

  return (
    <>
      <Button variant="cta" {...props} onClick={openModal}>
        Request a sample now →
      </Button>

      <StileXSampleModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        label="Request Stile X Sample Form"
        form={form}
        content={content}
      />
    </>
  );
}
