import * as React from 'react';

import { theme } from 'stile-shared/src/styles/2020/theme';

import { Box } from '../../layout/Box';
import { Column } from '../../layout/Column';
import { Grid } from '../../layout/Grid';

import { Modal } from '../Modal';
import { Heading } from '../Primitives';

type StileXSampleModalProps = React.ComponentProps<typeof Modal> & {
  form: React.ReactNode;
  content: React.ReactNode;
};

export function StileXSampleModal({ form, content, ...props }: StileXSampleModalProps) {
  return (
    <Modal {...props}>
      <Box space={theme.space.mToL} usePadding>
        <Grid min="450px" space={theme.space.xl}>
          <Column>
            <Heading as="h2">Request a sample of Stile X</Heading>
            {form}
          </Column>

          {content}
        </Grid>
      </Box>
    </Modal>
  );
}
